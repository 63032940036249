// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// IE11 compat - uncomment if needed
// require("core-js/stable");
// require("regenerator-runtime/runtime");
// end IE11 compat

import Rails from "@rails/ujs"
// import * as ActiveStorage from "@rails/activestorage"

window.Rails = Rails
Rails.start()
// ActiveStorage.start()

import LocalTime from "local-time"
LocalTime.start()
window.LocalTime = LocalTime


import "../src/js/foundation-inquiry"
import "../src/scss-inquiry/app.scss"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../src/img', true)
// const imagePath = (name) => images(name, true)
