import $ from "jquery"
import "foundation-sites"
import "what-input"

import "./flatpickr"

// Foundation.addToJquery($);
Foundation.Abide.defaults['validators']['datestamp'] =
  function ($evt, required, parent) {
    var evt = $evt;
    return evt[0].value.match('[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}') ? true : false;
  };

$(document).foundation();

$(document).ready(function () {
  $('form[data-abide]').bind('forminvalid.zf.abide', function (e, target) {
    // console.log('scrolling to first error', e, target)
    setTimeout(function () {
      $('.is-invalid-label, [data-invalid]')[0].scrollIntoView({ behavior: 'smooth' })
    });
  });

  //Phone require/toggling
  // $("#inquiry_customer_attributes_preferred_contact_method_phone").on("click", function () {
  //   $("#inquiry_customer_attributes_phone").attr("required", true);
  //   $("#inquiry_customer_attributes_email").removeAttr("required").removeClass("is-invalid-input");
  //   $("label[for=inquiry_customer_attributes_email]").removeClass("is-invalid-label");
  //   $(".form-error.email").removeClass("is-visible");
  // })
  //Email require/toggling
  // $("#inquiry_customer_attributes_preferred_contact_method_email").on("click", function () {
  //   $("#inquiry_customer_attributes_email").attr("required", true);
  //   $("#inquiry_customer_attributes_phone").removeAttr("required").removeClass("is-invalid-input");
  //   $("label[for=inquiry_customer_attributes_phone]").removeClass("is-invalid-label");
  //   $(".form-error.phone").removeClass("is-visible");
  // })

  //Event type Other toggle
  $('.event-type input[type=radio]').on('change', function () {
    if ($(this).attr("id") == "inquiry_event_type_code_other") {
      $("#inquiry_event_type_other").show();
      $("#inquiry_event_type_other").attr("required", true);
    }
    else {
      $("#inquiry_event_type_other").hide();
      $("#inquiry_event_type_other").attr("required", false);
    }
  });
  $('.event-style input[type=radio]').on('change', function () {
    if ($(this).attr("id") == "inquiry_event_style_code_other") {
      $("#inquiry_event_style_other").show();
      $(".event-other").show();
      $("#inquiry_event_style_other").attr("required", true);
    }
    else {
      $("#inquiry_event_style_other").hide();
      $(".event-other").hide();
      $("#inquiry_event_style_other").attr("required", false);
    }
  });
});
