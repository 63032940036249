import "flatpickr"

let addDays = (startDate, days) => {
  let date = new Date(startDate.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

$(document).ready(function () {
  //Event date flatpickr
  if ($('.flatpickr-input').length) {
    let startCal = $(".flatpickr-input").flatpickr({
      minDate: addDays(new Date(), 1)
    });

    $(".flatpickr-calendar").attr("role", "complementary");
    $(".flatpickr-calendar").each(function (index) {
      $(this).attr("aria-label", "Date picker-" + index);
    });
    $(".flatpickr-monthDropdown-months").attr("aria-label", "Select month");
    $(".fa-calendar-alt.start").on("click", function () {
      startCal.open();
    });
  }
});
